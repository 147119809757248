<script>
import { required, email } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import axios from 'axios';
import TOS from './tos';

export default {
  components: { TOS },
  data() {
    return {
      user: {
        nm_name:          "",
        nm_lastName:      "",
        de_email:         "",
        num_phoneNumber:  "",
        de_password:      ""
      },
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  validations: {
    user: {
      nm_name:          { required },
      nm_lastName:      { required },
      de_email:         { required, email },
      num_phoneNumber:  { required },
      de_password:      { required }
    }
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  methods: {
    /*
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    */
    // Try to register the user in with the email, username
    // and password they provided.
    tryToRegisterIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        let app = this;

        Swal.fire({
          title: "Creating account, please wait.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });
        
        axios
        .post(
          window.serverPath+'dist/mvw/controllers/users.php',
          {
            request: 'saveUser',
            data: this.user
          }
        )
        .then(response => {
          console.log(response);
          if( response.statusText == "OK" ){
            if(response.data.user){
              app.user          = response.data;
              this.user         = app.user;
              localStorage.setItem( 'user', JSON.stringify(this.user) );

              Swal.close();
              app.$router.push('/').catch((e) => e);
            }else{
              Swal.fire("Ooops!", "🤨 " + response.data.error + " <br>Please try again.", "error");
            }
          }else{
            Swal.fire("Ooops!", "Something is wrong with your credential🤨! Please try again.", "error");
          }
        })
        .catch(function (error) {
          if(
            error.code == "ERR_BAD_RESPONSE"    ||
            error.code == "ERR_NETWORK"
          ){
            Swal.fire("Ooops!", "Something is wrong with our service 😅!<br>Please try again.", "error");
          }
        })
      }
    }
  }
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-5">
            <div class="authentication-page-content p-4 d-flex align-items-center">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <!--<img src="@/assets/images/logo-dark.png" height="20" alt="logo" />-->
                            <img src="@/assets/images/logo.png" height="50" alt="BlueSeal" />
                          </a>
                        </div>

                        <h5 class="font-size-18 mt-2">Register account</h5>
                        <!--p class="text-muted">Get your free Nazox account now.</p>-->
                      </div>

                      <div class="p-2 mt-3">
                        <b-alert
                          v-model="registerSuccess"
                          class="mt-3"
                          variant="success"
                          dismissible
                        >Registration successfull.</b-alert>

                        <b-alert
                          v-model="isRegisterError"
                          class="mt-3"
                          variant="danger"
                          dismissible
                        >{{regError}}</b-alert>

                        <b-alert
                          variant="danger"
                          class="mt-3"
                          v-if="notification.message"
                          show
                          dismissible
                        >{{notification.message}}</b-alert>
                        
                        <form class="form-horizontal" @submit.prevent="tryToRegisterIn">
                          <div class="form-group auth-form-group-custom">
                            <i class="ri-user-2-line auti-custom-input-icon"></i>
                            <label for="nm_name">Name</label>
                            <input
                              v-model="user.nm_name"
                              type="text"
                              class="form-control"
                              id="nm_name"
                              :class="{ 'is-invalid': submitted && $v.user.nm_name.$error }"
                              placeholder="Enter your name"
                            />
                            <div
                              v-if="submitted && !$v.user.nm_name.required"
                              class="invalid-feedback"
                            >Name is required.</div>
                          </div>

                          <div class="form-group auth-form-group-custom">
                            <i class="ri-user-2-line auti-custom-input-icon"></i>
                            <label for="nm_lastName">Last name</label>
                            <input
                              v-model="user.nm_lastName"
                              type="text"
                              class="form-control"
                              id="nm_lastName"
                              :class="{ 'is-invalid': submitted && $v.user.nm_lastName.$error }"
                              placeholder="Enter your last name"
                            />
                            <div
                              v-if="submitted && !$v.user.nm_lastName.required"
                              class="invalid-feedback"
                            >Last name is required.</div>
                          </div>

                          <div class="form-group auth-form-group-custom">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="de_email">Email</label>
                            <input
                              v-model="user.de_email"
                              type="email"
                              class="form-control"
                              id="de_email"
                              placeholder="Enter your email"
                              :class="{ 'is-invalid': submitted && $v.user.de_email.$error }"
                            />
                            <div v-if="submitted && $v.user.de_email.$error" class="invalid-feedback">
                              <span v-if="!$v.user.de_email.required">Email is required.</span>
                              <span v-if="!$v.user.de_email.email">Please enter valid email.</span>
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom">
                            <i class="ri-phone-line auti-custom-input-icon"></i>
                            <label for="num_phoneNumber">Phone number</label>
                            <input
                              v-model="user.num_phoneNumber"
                              type="text"
                              class="form-control"
                              id="num_phoneNumber"
                              :class="{ 'is-invalid': submitted && $v.user.num_phoneNumber.$error }"
                              placeholder="Enter phone number"
                            />
                            <div
                              v-if="submitted && !$v.user.num_phoneNumber.required"
                              class="invalid-feedback"
                            >Phone number is required.</div>
                          </div>

                          <div class="form-group auth-form-group-custom">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="de_password">Password</label>
                            <input
                              v-model="user.de_password"
                              type="password"
                              class="form-control"
                              id="de_password"
                              placeholder="Enter password"
                              :class="{ 'is-invalid': submitted && $v.user.de_password.$error }"
                            />
                            <div
                              v-if="submitted && !$v.user.de_password.required"
                              class="invalid-feedback"
                            >Password is required.</div>
                          </div>

                          <div class="text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >Register</button>
                          </div>

                          <div class="mt-4 text-center">
                            <p class="mb-0">
                              By registering you agree to the Blue Seal
                              <a
                                href="#"
                                class="text-primary"
                                v-b-modal.modal-tos
                              >TOS</a>
                            </p>
                            <p>
                              Already have an account ?
                              <router-link
                                tag="a"
                                to="/login"
                                class="font-weight-medium text-primary"
                              >Login</router-link>
                            </p>
                          </div>
                          <b-modal
                            no-close-on-backdrop
                            id="modal-tos"
                            title="TERM OF SERVICE"
                            title-class="font-20"
                            hide-footer
                            scrollable
                          >
                            <TOS></TOS>
                          </b-modal>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>